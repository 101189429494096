$colorBlack: #333333;
.popup-cookies-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.lcc-text{
    font-size: 1.4rem !important;
}
.lcc-label>*{
    margin-left: 1rem;
    margin-right: 0;
}
.lcc-modal__section{
    .btn{
        width: 100%;
    }
    &.custom-border-bottom{
        .btn{
            width: calc(50% - .5rem);
            @media (max-width: 769px) {
                width: 100%;
            }
        }
    }
}
    .lcc-wrap-cookies{
        padding: 2rem;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
.lcc-modal {
    top: 0;
    &.lcc-modal--settings{
        max-height: 80%;
        overflow: auto;
        overflow-x: hidden;
        max-width: 80%;
        margin: auto;

        @media (min-width: 1001px) {
            max-width: 1000px !important;
        }

          @media (max-width: 769px) {
            margin-left: 3rem;
            margin-right: 3rem;
            min-height: 85%;
            max-height: 85%;
            max-width: 100%;
        }
    }
    &.lcc-modal--detail{
        padding: 35px;
        margin-left: 5rem;
        margin-right: 5rem;
        overflow: auto;
        min-height: 80%;
        max-height: 80%;

        @media (min-width: 1001px) {
            max-width: 1000px;
            margin: 0 auto;
        }

        @media (max-width: 769px) {
            margin-left: 3rem;
            margin-right: 3rem;
            min-height: 85%;
            max-height: 85%;
        }
    }
    .pr-cookie-group{
        border-bottom: 1px solid var(--background);
        background-color: #ffffff;
        border-radius: 0;
        &:last-of-type{
            border-bottom: none
        }
        span{
            color: $colorBlack !important;
        }
    }

    &#lcc-modal-detail{
        top: 50%;
        transform: translateY(-50%);
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
        width:5px;
    }
    &::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
        display: none;
    }
    &::-webkit-scrollbar:horizontal {
        padding-right: .5rem;
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: none !important;
        border: none;
    }
    &::-webkit-scrollbar-track {
        background-color:rgba(0,0,0, 0.1);
        border-radius: none !important;
    }

    .btn {
        font-size: 1.3rem;
        text-transform: uppercase;
        color: $colorBlack;
        height: 5rem;
        border-radius: 0;
        background-color: inherit;
    }
    .lcc-modal__content{
        padding-left: 0;
        padding-top: 2rem;
        text-align: left;

        .pr-cookie-view-title{
            margin-bottom: 1.5rem;
        }
    }
    .lcc-modal__actions {
        display: flex;
        align-items: flex-end;
        justify-content: space-between !important;

        .btn{
            width: calc(50% - .5rem);
            margin-left: 0;
        }
    }
    &.lcc-modal--settings .lcc-modal__close{
        width: 40px;
        height: 40px;
        font-size: 4rem;
        line-height: 1;
    }
    .btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
        outline: 0;
    }
}
.dialog-cookies {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    font-family: "DM Sans Regular", serif;

    .popup-cookies {
        right: 5rem;
        bottom: 5rem;
        background-color: #e1e1e5;
        z-index: 5;
        width: 77rem;
        height: 20rem;
        position: fixed;
        display: flex;

        .popup-cookies-text {
            padding-top: 2.5rem;
            padding-left: 2.5rem;
            font-size: 1.6rem;
            width: 60%;
            .wrap-cookies{
                padding: 2rem;
                padding-top: 4rem;
                padding-bottom: 4rem;
            }
            button {
                margin-top: 3rem;
                width: 20rem;
            }
        }

        a {
            color:$colorBlack;
            text-decoration: underline;
        }

        .exit {
            position: absolute;
            right: 0;
            padding-top: 1rem;
            padding-right: 1rem;
            width: 3rem;
            height: 3rem;
            cursor: pointer;
        }

        .dog {
            width: 100%;
        }
    }

    @media (max-width: 769px) {
        .popup-cookies {
            width: 35rem;
            height: 35rem;
            right: 1rem;
            bottom: 1rem;
            display: flex;
            flex-direction: column;

            .popup-cookies-text {
                width: 100%;
                padding-top: 4rem;

                button {
                    margin-top: 2rem;
                }
            }

            .popup-cookies-image {
                width: 100%;
                margin-bottom: 0;
                justify-content: center;
                margin-top: 2rem;
            }

       }
    }
}
.lcc-backdrop{
    background-color: transparent !important;
}
.content-cookies {
    position: fixed;
    top: 50%;
    left: 50%;
   transform: translate(-50%, -50%);
    overflow: visible;
    z-index: 10001;
    //min-width: 785px;
    //max-width: 785px;

    //box-shadow: rgba(0, 166, 184, 0.8) 0px 2px 4px 0px;
    box-shadow: 0 2px 10px rgba(0,0,0,.25);
    //top: unset;
    //left: unset;
    //right: 5rem;
    //bottom: 5rem;
    //transform: none;
    min-width: 50rem;
    max-width: 50rem;

    @media (max-width: 769px) {
        //max-width: 310px;
        //min-width: 310px;

        right: 2rem;
        bottom: 2rem;
        min-width: 310px;
        max-width: calc(100% - 4rem);
        width: calc(100% - 4rem);
        height: fit-content;
    }


    .cookies-text-message {
        max-width: 65rem;
        line-height: 1.42;
        color: $colorBlack;
        font-weight: normal;
        text-align: center;


        @media (max-width: 769px) {
            font-size: 1.4rem;
            line-height: 1.6rem;
            -webkit-box-ordinal-group: 2;
            -moz-box-ordinal-group: 2;
            -ms-flex-order: 2;
            -webkit-order: 2;
            order: 2;
        }
    }

    .lcc-modal-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;

        @media (max-width: 769px) {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            //flex-direction: column;
            /* optional */
            -webkit-box-align: start;
            -moz-box-align: start;
            -ms-flex-align: start;
            -webkit-align-items: flex-start;
            align-items: flex-start;
        }
        #lcc-modal-button-settings {
            text-transform: uppercase;
            display: inline;
            border: 0;
            width: 49%;
            height: 50px;
            font-family: inherit;
            font-size: 1.5rem;

            @media (max-width: 769px) {
                width: calc(50% - 0.5rem);
                /*display: block;
                -webkit-box-ordinal-group: 1;
                -moz-box-ordinal-group: 1;
                -ms-flex-order: 1;
                -webkit-order: 1;
                order: 1;*/
                //margin-top: 2.5rem;

            }
        }
    }
    .lcc-modal-buttons{
        .wrap-btnDefault-cookie{
            width: 49%;
            @media (max-width: 769px) {
                width: calc(50% - 0.5rem);
            }
        }
    }
    #lcc-modal-alert-desc{
        //text-align: left;
    }
    #lcc-modal-button-accept {
        text-transform: uppercase;
        display: inline;
        border: 0;
        width: 100%;
        height: 50px;
        font-family: inherit;
        font-size: 1.5rem;
        width: 100%;
        height: 50px;


        @media (max-width: 769px) {
            //font-size: 2.4rem;
            width: 100%;
            display: block;
            //margin-top: 2.5rem;
            //margin-bottom: 15px;
        }
    }
}

.custom-border-bottom:not(:last-child){
    border-bottom: 1px solid $colorBlack;
    max-width: 90rem;
    padding: 2rem 0rem;
}


/*.stop-scrolling {
    height: 100%;
    overflow: hidden;
}*/

.btn-accept-all {
    color: #ffffff;
    margin-top: 3rem;
    text-transform: uppercase;
    display: inline;
    border: 0;
    font-family: inherit;
    font-size: 1.5rem;
    width: 100%;
    height: 50px;
}
